<script>
import {deleteNota, list, updateNota} from '@/domain/leiloes/services/notas'
import {date, UTooltip} from 'uloc-vue'
import {datePtToEn} from '@/utils/date'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import DateInput from "@/reuse/input/Date"
import {listName, listStorage} from "../config/list"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import windowBem from '@/components/bem/windows/bem'
import windowArrematante from '@/components/arrematante/windows/arrematante'
import windowLote from '@/components/leiloes/windows/lote'
import {addDays, addMonths, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subDays} from "date-fns"
import {fileToBase64} from "@/utils/fileToBase64"
import {donwloadFile} from "@/utils/downloadFile"
import {importarItens, executarImportacao} from "@/domain/leiloes/services/entregaItens"
import DefaultAsyncSelect from "@/reuse/input/DefaultAsyncSelect"
import {listLeiloeiros} from "@/domain/leiloes/services"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import printNota from "components/pda/print/nota"
import notaWindow from '../windows/nota'

const filters = {
  id: null,
  active: true,
  deleted: false,
  search: null,
  data1: null,
  data2: null,
  periodo: 'hoje',
}

export default {
  name: 'Notas',
  props: {
    leilao: {required: false}
  },
  mixins: [DefaultMixin, ListMixin, GlobalPersonMixin],
  components: {
    MenuOptionsItem,
    DefaultAsyncSelect,
    DateInput,
    ErpInput,
    ErpSField,
    ErpSelect,
    UTooltip
  },
  data() {
    const filtroPeriodos = [
      {
        id: 'tudo', type: 'tudo',
        label: 'Tudo',
        value: 'tudo', active: false, action: function () {
          this.filters.data1 = null
          this.filters.data2 = null
        }
      },
      {
        id: 'hoje', type: 'day',
        label: 'Hoje',
        value: 'hoje', active: false, action: function () {
          this.filters.data1 = format(new Date(), 'dd/MM/yyyy')
          this.filters.data2 = format(new Date(), 'dd/MM/yyyy')
        }
      },
      {
        id: 'esta-semana', type: 'week',
        label: 'Esta semana',
        value: 'semana', active: false, action: function () {
          const start = addDays(startOfWeek(new Date()), 0);
          const end = subDays(endOfWeek(new Date()), 0);
          this.filters.data1 = format(start, 'dd/MM/yyyy')
          this.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      },
      {
        id: 'este-mes', type: 'month',
        label: 'Este mês',
        value: 'mes', active: true, action: function () {
          const end = endOfMonth(new Date());
          this.filters.data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
          this.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      }
    ]
    const _filters = JSON.parse(JSON.stringify(filters))
    if (this.leilao && this.leilao.id) {
      /*_filters.data1 = null
      _filters.data2 = null
      _filters.statusTipo = 'tudo'*/
      _filters.periodo = 'tudo'
    }
    return {
      listName: listName,
      listType: 'a',
      filters: _filters,
      tipoPeriodo: filtroPeriodos,
      loading: false,
      result: null,
      success: null,
      leiloeiro: null,
      listLeiloeiros: listLeiloeiros
    }
  },
  watch: {
    'filters.status' (v) {
      this.$nextTick(() => {
        this.pesquisar()
      })
    },
    'filters.periodo' (v) {
      this.$nextTick(() => {
        console.log(v)
        const f = this.tipoPeriodo.find(t => t.value === v)
        f && f.action && f.action.call(this)
        this.$nextTick(() => {
          this.load()
        })
      })
    }
  },
  computed: {
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = this.filters.data2 ? datePtToEn(this.filters.data2) : data1
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.tipoData && extraFilters.push(`&typeDate=${this.filters.tipoData}`)

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)
      this.filters.leiloeiro && extraFilters.push(`&leiloeiro=${this.filters.leiloeiro}`)
      this.leilao && this.leilao.id && extraFilters.push(`&leilao=${this.leilao.id}`)

      /*if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        extraFilters.push(`&status=${this.filters.status.join(',')}`)
      }*/

      // this.filters.pessoa && extraFilters.push(`&pessoa=${this.filters.pessoa.id || this.filters.pessoa}`)
      // this.filters.statusTipo !== null && extraFilters.push(`&statusTipo=${this.filters.statusTipo}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : 'true'}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.stats = data.stats
            if (data.counts) {
              this.counts = data.counts
            }

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    novo(t = null) {
      console.log('New...')
      this.windowVistoria(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    windowBem: windowBem,
    windowArrematante: windowArrematante,
    windowLote: windowLote,
    uploadAnalise (exportar = false) {
      let _exportar = exportar === true
      if (!_exportar) {
        this.result = null
      }
      console.log(this.$refs.input.files)
      if (!this.$refs.input.files || !this.$refs.input.files.length) {
        alert('Selecione um arquivo para analisar e migrar.')
        return
      }

      const file = this.$refs.input.files[0]
      file.filename = file.name
      console.log(file)

      this.loading = true

      fileToBase64(file)
          .then(_file => {
            importarItens({
              data: _file,
              module: 'entrega-itens',
              ...file
            }, _exportar)
                .then(response => {
                  this.loading = false
                  this.success = true
                  if (_exportar) {
                    donwloadFile(response)
                    return
                  }
                  console.log(response.data)
                  this.result = response.data
                  // resolve(true)
                })
                .catch(error => {
                  this.loading = false
                  this.alertApiError(error.response)
                  // resolve(false)
                })
          })
    },
    importar () {
      this.loading = true
      this.success = null
      executarImportacao(this.result.migration.id)
          .then(response => {
            this.loading = false
            this.success = true
            this.$uloc.dialog({
              title: 'Importação concluída',
              message: `Importação realizada com sucesso.`,
              noCaps: true,
              ok: `Ok`
            })
            this.$router.push({name: 'leiloes.show'})
            // resolve(true)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error.response)
            // resolve(false)
          })
    },
    hasValidArremates (nota) {
      let valid = false
      nota.arremates && nota.arremates.map(a => {
        if (a.lote && a.status < 3) {
          valid = true
        }
      })
      return valid
    },
    printNotaWindow: printNota,
    segundavia (nota) {
      this.printNotaWindow(nota.lotes.map(lt => {
        return {id: lt}
      }), 'unificado', false, false)
    },
    notaWindow,
    cancelar (id) {
      this.$uloc.dialog({
        title: 'Cancelar Nota',
        message: 'Você tem certeza que deseja cancelar esta nota?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show()
        updateNota(id, {cancelada: true})
            .then(() => {
              this.$uloc.loading.hide()
              this.dg()
              this.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {})
    },
    deletar (id) {
      this.$uloc.dialog({
        title: 'Cancelar Nota',
        message: 'Você tem certeza que deseja excluir permanente esta nota?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show()
        deleteNota(id)
            .then(() => {
              this.$uloc.loading.hide()
              this.dg()
              this.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {})
    }
  },
  meta: {
    title: 'Notas',
    name: 'Notas'
  }
}
</script>

<template>
  <div class="vistoria-container wrapper-md page-leilao-notas">
    <e-row class="m-b-md header-title">
      <e-col style="max-width: 350px">
        <h5 class="">Notas emitidas</h5>
        <!--<resumo v-if="!isEmbedComponent" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
      </e-col>
      <e-col class="justify-end flex">
        <!--<u-btn no-caps color="green" class="app-btn font-12 m-r-sm">Registrar Nova Entrega</u-btn>-->
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
<!--        <div class="actives-btns col-grow-1">
          <u-btn @click="changeListType('t')" :label="label('Todas', 't')" class="active-table-btn"
                 :class="{active: listType === 't'}" no-caps/>
          <u-btn @click="changeListType('a')" :label="label('Ativas', 'a')" class="active-table-btn"
                 :class="{active: listType === 'a'}" no-caps/>
          <u-btn @click="changeListType('f')" :label="label('Finalizadas', 'f')" class="active-table-btn"
                 :class="{active: listType === 'f'}" no-caps/>
        </div>-->
        <div class="end font-14 bg-white flex-zero flex no-wrap">
          <e-col style="min-width: 300px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input shortkey="F3" v-model="filters.search" @keydown.enter="pesquisar" placeholder="Número, CPF, ID, Chave, Bem, Cliente"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)"
                        v-model="filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)"
                        v-model="filters.data2"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <erp-select v-model="filters.periodo" placeholder="Período" :options="tipoPeriodo" />
          </e-col>
<!--          <e-col style="min-width: 150px" class="m-r">
            <erp-select v-model="filters.statusTipo" placeholder="Qualquer" :options="[
                {label: 'Para Retirar', value: 'retirar'},
                {label: 'Retirado', value: 'retirado'},
            ]" />
          </e-col>-->
          <e-col style="min-width: 250px" class="m-r">
            <default-async-select simple-border field-view="lr" label="Leiloeiro:" placeholder="Todos"
                                  v-model="filters.leiloeiro" :list="listLeiloeiros" />
          </e-col>
          <e-col style="min-width: 50px" >
            <u-btn @click="load" icon="refresh" flat size="sm" />
          </e-col>
          <!--              <e-col style="min-width: 150px">
                          <erp-s-field
                              view="ll"
                              label="Filtro Data"
                          >
                            <erp-select
                                :options="[
                                    {label: 'Criação', value: 'createdAt'},
                                    {label: 'Início', value: 'startTime'},
                                    {label: 'Prazo', value: 'deadline'},
                                ]"
                                v-model="filters.tipoData" />
                          </erp-s-field>
                        </e-col>
                        <e-col style="min-width: 150px">
                          <status-task-select field-view="ll" multiple v-model="filters.status" @change="load()" code-as-value />
                        </e-col>-->

        </div>
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="fila-table"
          :rows-per-page-options="[20, 50, 100, 500, 1000]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
<!--          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props"
              :props="props" style="cursor: default">
<!--          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>-->
          <u-td key="numero" :props="props">
            {{ props.row.numero }}
          </u-td>
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="data" :props="props" class="">
            <span v-if="props.row.createdAt">{{ props.row.createdAt|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
            <span v-if="props.row.dataEmissaoManual" class="text-purple m-l">{{ props.row.dataEmissaoManual|formatDate('dd/MM/yyyy') }}</span>
          </u-td>
          <u-td key="leilao" class="td-limit l-200" :props="props">
            <div v-if="props.row.arremates">
            <span v-for="a in props.row.arremates" :key="'a1-' + a.id" class="reverse">
              <template v-if="a.lote">
              <a @click="gotoLeilao(a.lote.leilao)" class="text-grey-8">{{ a.lote.leilao.titulo }}</a>
              <u-tooltip content-class="bg-indigo" :offset="[5, 5]">{{ a.lote.leilao.titulo }}</u-tooltip>
              </template>
            </span>
            </div>
          </u-td>
          <u-td key="lote" :props="props">
            <span v-if="props.row.lotes">
              <a v-for="lote in props.row.lotes" @click="windowLote({id: lote})" class="text-grey-8">ID {{ lote }}</a>
            </span>
          </u-td>
          <u-td key="bem" :props="props" class="td-limit l-200">
            <div v-if="props.row.arremates">
            <span v-for="a in props.row.arremates" :key="'a2-' + a.id">
              <div v-if="a.lote">
              <a @click="windowBem(a.lote.bem.id)" class="text-grey-8 td-limit l-200">{{ a.lote.bem.siteTitulo }}</a>
              <u-tooltip content-class="bg-indigo" :offset="[5, 5]">{{ a.lote.bem.siteTitulo }}</u-tooltip>
              </div>
            </span>
            </div>
          </u-td>
          <u-td key="arrematante" :props="props" class="">
            <div v-if="props.row.arremates">
              <span v-for="a in props.row.arremates" :key="'a3-' + a.id">
              <a v-if="a.comprador" @click="windowArrematante(a.comprador.id)" class="text-grey-8">{{ a.comprador.pessoa.name }}</a>
              <a v-else @click="windowArrematante(a.arrematante.id)" class="text-grey-8">{{ a.arrematante.pessoa.name }}</a>
              </span>
            </div>
          </u-td>
          <u-td key="chave" :props="props" class="">
            <span>{{props.row.chaveSeguranca}}</span>
          </u-td>
          <u-td key="valor" :props="props" class="">
            <span v-if="props.row.valor">R$ {{ props.row.valor|moeda }}</span>
          </u-td>
          <u-td key="status" :props="props">
            <span v-if="props.row.cancelada" class="u-chip u-chip-small bg-negative text-white font-bold font-12">Cancelada</span>
            <span v-else-if=" !hasValidArremates(props.row)" class="u-chip u-chip-small bg-purple text-white font-bold font-12">
              Inválida
              <u-tooltip>Significa que o arremate foi cancelado mas a nota ainda não foi cencelada</u-tooltip>
            </span>
            <span v-else class="u-chip u-chip-small bg-green text-white font-bold font-12">Válida</span>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Segunda-via" @click="segundavia(props.row)" />
                  <menu-options-item close label="Modificar" @click="notaWindow(props.row.id)" />
                  <menu-options-item close label="Cancelar" @click="cancelar(props.row.id)" label-class="text-negative" />
                  <menu-options-item close label="Excluir" @click="deletar(props.row.id)" label-class="text-negative" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>

      <!--<div class="flex justify-between">
        <div class="m-t">
          <u-btn icon="print" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
          </u-btn>
          <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
          </u-btn>
        </div>
        <div>

        </div>
      </div>-->
    </div>
  </div>
</template>
